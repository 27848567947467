import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import TransitionLink, { TransitionState } from 'gatsby-plugin-transition-link';

import Layout from '../components/Layout';
import Footer from '../components/Footer';

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Back = styled(TransitionLink)`
  position: relative;
  display: block;
  margin-top: 30px;
  background-color: transparent;
  transition: background-color 0.15s ease-in-out;
  border-radius: 24px;
  padding: 5px 20px 7px;
  line-height: 20px;

  @media (min-width: 700px) {
    &:hover {
      background-color: var(--color-8080);
    }

    .body-color &:hover,
    .body-color-no-anim &:hover {
      background-color: var(--secondary);
    }
  }
`;

const ContentWrapper = styled.div`
  max-width: 900px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 50px 20px 60px;
  flex-wrap: wrap;
  opacity: ${({ mount }) => (mount ? 1 : 0)};

  @media (min-width: 700px) {
    transition: opacity 0.45s ease-in-out;
    margin: 100px 10px 120px;
  }
`;

const Description = styled.div`
  max-width: 100%;
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 100px;

  @media (min-width: 700px) {
    width: 100%;
    max-width: 55%;
    margin-bottom: 0;
  }
`;

const Contact = styled.div`
  flex-grow: 1;
  text-align: center;
  width: 100%;

  @media (min-width: 700px) {
    width: auto;
  }
`;

const Emojis = styled.div`
  font-size: 50px;
  line-height: 1;
  margin-bottom: 30px;
`;

const Emoji = styled.span`
  display: inline-block;
  position: relative;

  &:hover {
    &:after {
      position: absolute;
      content: '';
      font-size: 12px;
      top: 0;
      transform: translateY(-100%);
      left: calc(50% - 50px);
      width: 100px;
      text-align: center;
    }
  }
`;

const Aleks = styled(Emoji)`
  &:hover {
    &:after {
      content: 'Heyo!';
      top: -5px;
    }
  }
`;

const Franek = styled(Emoji)`
  &:hover {
    &:after {
      content: "Hi!";
      left: -10px;
      text-align: left;
    }
  }
  `;

const Radek = styled(Emoji)`
  &:hover {
    &:after {
      content: "What's up!";
      left: auto;
      right: -50px;
      text-align: right;
    }
  }
`;

const StyledLink = styled.a`
  display: block;
  font-size: 18px;
  margin-bottom: 10px;
  text-decoration: underline;
  cursor: pointer;

  @media (min-width: 700px) {
    &:hover {
      text-decoration: none;
    }
  }
`;

const Content = ({ data, mount }) => {
  const [mailText, setMailText] = useState(data.mail);

  useEffect(() => {
    if (mount) {
      toTop();
    }
  }, [mount]);

  const toTop = () => {
    if (typeof window !== ' undefined') {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
  };

  const handleMailClick = async (event) => {
    if (!navigator.clipboard) {
      // Clipboard API not available
      const copyToClipboard = (textToCopy) => {
        var textArea;

        const isOS = () => {
          //can use a better detection logic here
          return navigator.userAgent.match(/ipad|iphone/i);
        };

        const createTextArea = (text) => {
          textArea = document.createElement('textArea');
          textArea.readOnly = true;
          textArea.contentEditable = true;
          textArea.value = text;
          document.body.appendChild(textArea);
        };

        const selectText = () => {
          var range, selection;

          if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
          } else {
            textArea.select();
          }
        };

        const copyTo = () => {
          document.execCommand('copy');
          document.body.removeChild(textArea);
        };

        createTextArea(textToCopy);
        selectText();
        copyTo();

        setMailText('Copied');
        setTimeout(() => {
          setMailText(data.mail);
        }, 2000);
      };

      copyToClipboard(event.target.innerText);

      return;
    }
    const text = event.target.innerText;
    const ev = event;
    try {
      await navigator.clipboard.writeText(text);
      setMailText('Copied');
      setTimeout(() => {
        setMailText(data.mail);
      }, 2000);
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  };

  return (
    <ContentWrapper mount={mount}>
      <Description>{data.text}</Description>
      <Contact>
        <Emojis>
          <Franek>👨‍💻</Franek>
          <Aleks>👩‍💻</Aleks>
          <Radek>👨‍💻</Radek>
        </Emojis>
        <StyledLink as='div' onClick={handleMailClick}>
          {mailText}
        </StyledLink>
        <StyledLink href={data.insta} target='_blank'>
          Follow
        </StyledLink>
      </Contact>
    </ContentWrapper>
  );
};

const AboutPageContent = ({ data }) => {
  const { markdownRemark: post } = data;
  const isMobile = useMediaQuery({ query: '(max-width: 700px)' });

  return (
    <TransitionState>
      {({ mount, transitionStatus }) => {
        return (
          <Layout transitionStatus={transitionStatus} page='about'>
            <Wrapper>
              <Back to='/'>Back</Back>
              <Content data={data} mount={mount} />
              <Footer isMobile={isMobile} mail={data.mail} invert='true' active='about' />
            </Wrapper>
          </Layout>
        );
      }}
    </TransitionState>
  );
};

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <AboutPageContent data={frontmatter} />;
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        text
        mail
        insta
      }
    }
  }
`;
